// NFTPayButton.js
import React from 'react';

const NFTPayButton = () => {
  const handleClick = () => {
    // Define any additional logic or actions when the button is clicked
    show_creatify_popup();
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://payments.nftpay.xyz/css/iframe_inject.css"
      />
      <script
        id="creatify_script"
        src="https://payments.nftpay.xyz/libs/iframe_inject.js?contract_uuid=d771a7ef-a4c9-4b3b-aa45-98038ce66825"
      ></script>

      <button
        style={{
          padding: '10px',
          backgroundColor: 'rgba(255, 0, 0, 0.5)',  // Semi-transparent red background
          color: 'gold',  // Gold-colored text
          border: '2px solid red',  // Red border
          borderRadius: '10px',  // Rounded corners
          cursor: 'pointer',
          textTransform: 'uppercase',  // All-capital text
          fontWeight: 'bold',  // Bold font
        }}
        onClick={handleClick}
      >
        Buy with card{' '}
        <span id="price-d771a7ef-a4c9-4b3b-aa45-98038ce66825">
          <img
            className="nftpay_spinner"
            src="https://payments.nftpay.xyz/img/icons/spinner2.svg"
            alt="Loading Spinner"
          />
        </span>
      </button>
    </div>
  );
};

export default NFTPayButton;
